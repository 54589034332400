<template>
    <div>
        <EventsList />
    </div>
</template>

<script>
import EventsList from '@/components/EventsList.vue'
export default {
  components: {
    EventsList
  }
}
</script>
