<template>
    <div>
        <b-container>
            <section class="events">
                <div class="sector pb-0">
                    <h2 class="main-title">Events</h2>
                    <p class="main-subtitle">Connect to the community</p>
                    <b-row>
                        <b-col cols="12" lg="4">
                            <div class="input-box">
                                <div v-if="filterEvent" @click.prevent="clearFilter" class="clear-icon clear-icon--filter">
                                    <simple-line-icons icon="close" size="small" color="#757575" />
                                </div>
                                <input v-model="filterEvent" class="custom-search-bar" type="text" placeholder="Search by event name or city or country" />
                            </div>
                        </b-col>
                        <b-col cols="12" lg="4">
                            <div class="input-box">
                                <div v-if="selectedType" @click.prevent="clearType" class="clear-icon">
                                    <simple-line-icons icon="close" size="small" color="#757575" />
                                </div>
                                <v-select id="selectedType" class="custom-sorter" labelTitle = "Sort by type" v-model="selectedType" :options="types" />
                            </div>
                        </b-col>
                        <b-col cols="12" lg="4">
                            <div class="input-box">
                                <div v-if="selectedCountry" @click.prevent="clearCountry" class="clear-icon">
                                    <simple-line-icons icon="close" size="small" color="#757575" />
                                </div>
                                <v-select id="selectedCountry" class="custom-sorter" labelTitle = "Sort by country" v-model="selectedCountry" :options="countries" :searchable = true />
                            </div>
                        </b-col>
                    </b-row>
                    <div v-if="getFilteredEvents.length == 0 && loader" class="mb-3">
                        <Spinner size="medium" line-fg-color="#e91e63" />
                    </div>
                    <div id="current" v-else>
                        <div v-if="filterEvent != '' || selectedCountry != null || selectedType != null">
                        <paginate name="eventsStore" :list="getFilteredEvents" tag="div" :per="9" class="paginate-list">
                            <b-row>
                                <b-col v-for="event in paginated('eventsStore')" :key="event.id"  cols="12" md="6" lg="4">
                                    <div class="events__element">
                                        <div v-if="event.ribbon" class="custom-ribbon custom-ribbon--right">
                                            <span class="custom-ribbon__text custom-ribbon__text--red">Featured</span>
                                        </div>
                                        <div class="events__image" v-lazy:background-image="event.url"></div>
                                        <div class="events__content">
                                            <p class="events__type">{{ event.type.text }}</p>
                                            <h2 class="events__title" v-line-clamp:22="1">{{ event.name }}</h2>
                                            <p class="events__text" v-html="event.desc" v-line-clamp:24="3"></p>
                                            <b-row>
                                                <b-col cols="6">
                                                    <span v-line-clamp:24="2" class="events__place"><span class="agencies__star-tooltip" v-b-tooltip title="Location"><simple-line-icons icon="locationPin" size="small" color="#504e70" /></span>{{ event.city }}, {{ event.country.text }}</span>
                                                </b-col>
                                                <b-col cols="6">
                                                    <span class="events__date"><span class="agencies__star-tooltip" v-b-tooltip title="Date"><simple-line-icons icon="calendar" size="small" color="#504e70" /></span>{{ event.date }}</span>
                                                </b-col>
                                            </b-row>
                                        </div>
                                        <div class="events__details">
                                            <router-link :to="{ name: 'event', params: { id: event.id } }" class="details-button details-button--full"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />View details</router-link>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </paginate>
                        <paginate-links @change="scrollToTop" v-if="getFilteredEvents.length != 0" for="eventsStore" :limit="3" :show-step-links="true" :classes="{'ul': 'pagination','.next > a': 'next-link','.prev > a': 'prev-link'}"></paginate-links>
                        </div>
                        <div v-else>
                            <paginate name="eventsList" :list="getFilteredEvents" tag="div" :per="9" class="paginate-list">
                                <b-row>
                            <b-col v-for="event in paginated('eventsList')" :key="event.id"  cols="12" md="6" lg="4">
                            <div class="events__element">
                                <div v-if="event.ribbon" class="custom-ribbon custom-ribbon--right">
                                    <span class="custom-ribbon__text custom-ribbon__text--red">Featured</span>
                                </div>
                                <div class="events__image" v-lazy:background-image="event.url"></div>
                                <div class="events__content">
                                    <p class="events__type">{{ event.type.text }}</p>
                                    <h2 class="events__title" v-line-clamp:22="1">{{ event.name }}</h2>
                                    <p class="events__text" v-html="event.desc" v-line-clamp:24="3"></p>
                                    <b-row>
                                        <b-col cols="6">
                                            <span v-line-clamp:24="2" class="events__place"><span class="agencies__star-tooltip" v-b-tooltip title="Location"><simple-line-icons icon="locationPin" size="small" color="#504e70" /></span>{{ event.city }}, {{ event.country.text }}</span>
                                        </b-col>
                                        <b-col cols="6">
                                            <span class="events__date"><span class="agencies__star-tooltip" v-b-tooltip title="Date"><simple-line-icons icon="calendar" size="small" color="#504e70" /></span>{{ event.date }}</span>
                                        </b-col>
                                    </b-row>
                                </div>
                                <div class="events__details">
                                    <router-link :to="{ name: 'event', params: { id: event.id } }" class="details-button details-button--full"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />View details</router-link>
                                </div>
                            </div>
                        </b-col>
                        </b-row>
                        </paginate>
                        <paginate-links @change="scrollToTop" v-if="getFilteredEvents.length != 0" for="eventsList" :limit="3" :show-step-links="true" :classes="{'ul': 'pagination','.next > a': 'next-link','.prev > a': 'prev-link'}"></paginate-links>
                        </div>
                    </div>
                    <div v-if="getFilteredEvents.length == 0 && getEventsPending && !loader" class="mb-3">
                        <p class="text-center result-text">No results found</p>
                    </div>
                </div>

                <div v-if="getFilteredPastEvents.length !== 0 && getEventsPending" class="sector pb-0">
                    <h2 class="main-title">Past Events</h2>
                    <p class="main-subtitle">Have a look on events that took place in the past</p>
                    <paginate name="pastEvents" :list="getFilteredPastEvents" tag="div" :per="9" class="paginate-list">
                    <b-row id="past">
                        <b-col v-for="event in paginated('pastEvents')" :key="event.id"  cols="12" md="6" lg="4">
                            <div class="events__element">
                                <div v-if="event.ribbon" class="custom-ribbon">
                                    <span class="custom-ribbon__text custom-ribbon__text--red">Featured</span>
                                </div>
                                <div class="events__image" v-lazy:background-image="event.url"></div>
                                <div class="events__content">
                                    <p class="events__type">{{ event.type.text }}</p>
                                    <h2 class="events__title" v-line-clamp:24="2">{{ event.name }}</h2>
                                    <p class="events__text" v-html="event.desc" v-line-clamp:24="3"></p>
                                    <b-row>
                                        <b-col cols="6">
                                            <span v-line-clamp:24="2" class="events__place"><span class="agencies__star-tooltip" v-b-tooltip title="Location"><simple-line-icons icon="locationPin" size="small" color="#504e70" /></span>{{ event.city }}, {{ event.country.text }}</span>
                                        </b-col>
                                        <b-col cols="6">
                                            <span class="events__date"><span class="agencies__star-tooltip" v-b-tooltip title="Date"><simple-line-icons icon="calendar" size="small" color="#504e70" /></span>{{ event.date }}</span>
                                        </b-col>
                                    </b-row>
                                </div>
                                <div class="events__details">
                                    <router-link :to="{ name: 'event', params: { id: event.id } }" class="details-button details-button--full"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />View details</router-link>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                    </paginate>
                    <paginate-links @change="scrollToTopPast" v-if="getFilteredPastEvents.length != 0" for="pastEvents" :limit="3" :show-step-links="true" :classes="{'ul': 'pagination','.next > a': 'next-link','.prev > a': 'prev-link'}"></paginate-links>
                </div>
            </section>
        </b-container>
    </div>
</template>

<script>
import SimpleLineIcons from 'vue-simple-line'
import VSelect from '../assets/files/customSelect.esm.js'
import countriesList from '../assets/files/countriesList.js'
import eventTypesList from '../assets/files/eventTypesList.js'
import { mapGetters } from 'vuex'
import Spinner from 'vue-simple-spinner'
import moment from 'moment'
import VuePaginate from 'vue-paginate'
import MoveTo from 'moveto'
export default {
  components: {
    SimpleLineIcons,
    VSelect,
    Spinner
  },
  data: function () {
    return {
      loader: true,
      selectedCountry: null,
      selectedType: null,
      countries: [],
      types: [],
      filterEvent: '',
      paginate: ['eventsList', 'eventsStore', 'pastEvents']
    }
  },
  methods: {
    clearType () {
      this.selectedType = null
      if (document.querySelector('#selectedType li.selected') != undefined) {
        document.querySelector('#selectedType li.selected').classList.remove('selected')
      }
    },
    clearCountry () {
      this.selectedCountry = null
      if (document.querySelector('#selectedCountry li.selected') != undefined) {
        document.querySelector('#selectedCountry li.selected').classList.remove('selected')
      }
    },
    clearFilter () {
      this.filterEvent = ''
    },
    scrollToTop (toPage, fromPage) {
      window.history.replaceState(null, '', this.$route.path + '?page=' + toPage)

      const moveTo = new MoveTo({
        tolerance: 75,
        duration: 1000
      })

      const target = document.getElementById('current')

      moveTo.move(target)
    },
    scrollToTopPast (toPage, fromPage) {
      window.history.replaceState(null, '', this.$route.path + '?past=' + toPage)

      const moveTo = new MoveTo({
        tolerance: 75,
        duration: 1000
      })

      const target = document.getElementById('past')

      moveTo.move(target)
    }
  },
  computed: {
    ...mapGetters([
      'getEvents',
      'getEventsPending'
    ]),
    getStoreEvents () {
      var storeEvents = Object.values(this.getEvents)
      return storeEvents
    },
    getFilteredEvents () {
      if (this.filterEvent != '' || this.selectedCountry != null || this.selectedType != null) {
        var events = Object.values(this.getStoreEvents).sort((a, b) => new Date(moment(a.date, 'DD-MM-YYYY')) - new Date(moment(b.date, 'DD-MM-YYYY')))

        var sorted = events.sort((a, b) => b.ribbon - a.ribbon).filter((event) => {
          return (new Date(moment(event.date, 'DD-MM-YYYY').format('YYYY-MM-DD')).setHours(0, 0, 0, 0) >= new Date().setDate(new Date().getDate() - 1) && (event.name.toLowerCase().includes(this.filterEvent.toLowerCase()) || event.city.toLowerCase().includes(this.filterEvent.toLowerCase()) || event.country.text.toLowerCase().includes(this.filterEvent.toLowerCase())))
        })

        this.paginate.eventsStore.page = 0

        if (this.$route.query.eventParam != undefined) {
          window.history.replaceState(null, '', this.$route.path + '?page=' + 1 + '&eventParam=' + this.$route.query.eventParam)
        } else {
          window.history.replaceState(null, '', this.$route.path + '?page=' + 1)
        }

        if (this.selectedCountry != null && this.selectedType == null) {
          return sorted.filter((sorted) => {
            return sorted.country.text.toLowerCase() == this.selectedCountry.value
          })
        } else if (this.selectedCountry == null && this.selectedType != null) {
          return sorted.filter((sorted) => {
            return sorted.type.text.toLowerCase() == this.selectedType.value
          })
        } else if (this.selectedCountry != null && this.selectedType != null) {
          return sorted.filter((sorted) => {
            return sorted.country.text.toLowerCase() == this.selectedCountry.value && sorted.type.text.toLowerCase() == this.selectedType.value
          })
        } else {
          return sorted
        }
      } else {
        if (this.$route.query.page != undefined) {
          this.paginate.eventsList.page = this.$route.query.page - 1
        }
        var events = Object.values(this.getStoreEvents).sort((a, b) => new Date(moment(a.date, 'DD-MM-YYYY')) - new Date(moment(b.date, 'DD-MM-YYYY')))

        var sorted = events.sort((a, b) => b.ribbon - a.ribbon).filter((event) => {
          return (new Date(moment(event.date, 'DD-MM-YYYY').format('YYYY-MM-DD')).setHours(0, 0, 0, 0) >= new Date().setDate(new Date().getDate() - 1))
        })

        return sorted
      }
    },
    getFilteredPastEvents () {
      if (this.$route.query.past != undefined) {
        this.paginate.pastEvents.page = this.$route.query.past - 1
      }
      var events = Object.values(this.getStoreEvents).sort((a, b) => new Date(moment(b.date, 'DD-MM-YYYY')) - new Date(moment(a.date, 'DD-MM-YYYY')))

      var sorted = events.sort((a, b) => b.ribbon - a.ribbon).filter((event) => {
        return (new Date(moment(event.date, 'DD-MM-YYYY').format('YYYY-MM-DD')).setHours(0, 0, 0, 0) <= new Date().setDate(new Date().getDate() - 1))
      })

      return sorted
    }
  },
  beforeCreate () {
    this.$store.dispatch('getEvents')
  },
  created () {
    if (this.$route.query.eventParam != undefined) {
      this.filterEvent = this.$route.query.eventParam
    }

    this.countries = countriesList
    this.types = eventTypesList

    setTimeout(function () { this.loader = false }.bind(this), 5000)
  }
}
</script>
